.table-setting-list {
  z-index: 5;
  width: auto;
  min-width: 140px;
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 3px 2px #d4d4d4;
  .ant-checkbox-group-item {
    width: 100%;
    padding: 4px 0;
  }
  .table-column-check-list {
    overflow-y: auto;
    max-height: 300px;
    overflow-x: hidden;
  }
  .ant-checkbox-group {
    display: block;
    width: 100%;
  }
  .ant-checkbox {
    display: inline-block;
  }
  .ant-checkbox + span {
    display: inline-block;
  }
}

.table-setting{
  display: flex;
  align-items: center;
  justify-content: center;
}