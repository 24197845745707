.tab-form {
  width: 100%;
  padding-top: 12px;
  background-color: #fff;
  .ant-tabs-content {
    padding: 16px;
  }
  &.fixed-actionbar-form {
    margin-bottom: 60px;
  }
}
