.step-form {
  &.ant-space-vertical {
    width: 100%;
    > .ant-space-item {
      ~ .ant-space-item {
        margin-top: 30px;
      }
    }
  }
}
