$controlHeight: 40px;
$controlorderRadius: 4px;
.ant-form {
  .ant-form-item-label {
    label {
      height: $controlHeight;
    }
  }
  .ant-radio-group {
    height: $controlHeight;
    // line-height: 40px;
    display: flex;
    align-items: center;
  }
  .ant-picker-input,
  .ant-input {
    height: calc($controlHeight - 8px);
  }
  .ant-picker {
    width: 100%;
    border-radius: $controlorderRadius;
  }
  .ant-select-single,
  .ant-tree-select,
  .ant-select-multiple {
    .ant-select-selector {
      height: $controlHeight;
      border-radius: $controlorderRadius;
    }
    .ant-select-selection-search-input {
      height: calc(calc($controlHeight - 8px) - 2px);
    }
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      line-height: calc($controlHeight - 2px);
    }
  }
  .ant-select-multiple {
    .ant-select-selection-item {
      line-height: 24px;
    }
  }
}
