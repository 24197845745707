.ant-btn {
  border-radius: $baseBorderRadius;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
}
.ant-switch {
  background-color: rgba(0, 0, 0, 0.25);
}
.ant-btn-primary,
.ant-switch-checked {
  background-color: #4477f6;
}
.ant-popover {
  .ant-btn {
    height: 32px;
  }
}

.ant-popover-buttons {
  .ant-btn {
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }
}
