.section {
  background-color: #fff;
  border-radius: 8px;
  // padding: 20px;
  margin-top: 20px;
  .section-head {
    padding: 20px 24px 20px 50px;
    border-bottom: 1px solid #f2f2f2;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    position: relative;
    &::before {
      position: absolute;
      left: 24px;
      top: calc(50% - 8px);
      content: " ";
      // background: transparent url("#{$imgPath}/icon-dot.png") no-repeat center
      //   center;
      width: 16px;
      height: 16px;
      background-size: contain;
    }
  }
  .section-body {
    padding: 20px;

    .ant-btn {
      // margin-top: 12px;
    }
  }
  .ant-col-center {
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-form-item {
      width: 100%;
    }
  }
}
