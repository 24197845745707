.ant-form-item-control-input {
  .ant-form-item-control-input-content {
    .ant-input,
    .ant-select-selector {
      border-radius: $baseBorderRadius;
    }
  }
}
.ant-input-affix-wrapper {
  border-radius: $baseBorderRadius;
  .ant-input {
    height: 30px;
  }
}

.ant-input,
.ant-select-selector,
.ant-input-number,
.ant-picker {
  border-radius: $baseBorderRadius;
}

.ant-form {
  .ant-input,
  .ant-select-selector,
  .ant-input-number,
  .ant-picker {
    border-radius: $baseBorderRadius !important;
  }
}
