$menuSelectedBgColor: rgb(255 255 255 / 0.19);
$menuSelectedColor: #fff;
$menuHoverBgColor: rgb(255 255 255 / 0.1);
$menuColor: #ccd2e3;
$menuPadding: 16px 12px;
$menuItemHeight: 46px;
$borderRadius: 8px;

.ant-menu{
  font-size: 15px !important;
  border-right: 0 !important;
}

.submenu-box {
  background: transparent;
  color: $menuColor!important;
  font-size: 16px;
  padding: 28px 15px;
  border: 0px;
  .anticon {
    font-size: 18px !important;
  }
  .ant-menu-submenu {
    .ant-menu-sub {
      background: transparent !important;
      color: $menuColor;
    }
    .ant-menu-submenu-arrow {
      color: $menuColor;
    }
    .ant-menu-submenu-title {
      padding: $menuPadding;
      height: $menuItemHeight;
      border-radius: $borderRadius;
      line-height: 1;
      &:hover {
        background-color: $menuHoverBgColor !important;
        color: $menuColor !important;
      }
    }

    &.ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        background-color: $menuSelectedBgColor !important;
        color: $menuSelectedColor;
      }
      .ant-menu-submenu-arrow {
        color: $menuSelectedColor;
      }
    }
  }
  .ant-menu-item {
    padding: $menuPadding;
    height: $menuItemHeight;
    line-height: 1;
    border-radius: $borderRadius;
    &:not(.ant-menu-item-selected):hover {
      background-color: $menuHoverBgColor !important;
      color: $menuColor !important;
    }
    &::after {
      display: none;
    }
    .anticon {
      line-height: $menuItemHeight !important;
    }
  }
  .ant-menu-item-selected {
    background-color: $menuSelectedBgColor !important;
    color: $menuSelectedColor;
  }
}
