.ant-radio-wrapper {
  margin-right: 0px;
}
.ant-form-item {
  margin-bottom: 16px !important;
}

.form-layout {
  background: rgba(255, 255, 255, 0.8);
  padding: 25px 20px 60px;
  border-radius: 8px;
  box-shadow: rgb(212 212 212) 0px 2px 2px 1px;
  .title {
    font-size: 18px;
    padding-bottom: 20px;
  }
}

.ant-input[disabled]::placeholder {
  font-size: 0;
}
.ant-input[disabled] {
  color: rgba($color: #333, $alpha: 0.8) !important;
}

$inputBorderRadius: 4px;

$controlHeight: 40px;
$controlorderRadius: 4px;
.ant-form {
  .ant-form-item-control-input {
    .ant-form-item-control-input-content {
      .ant-input,
      .ant-input-number {
        border-radius: $inputBorderRadius;
      }
    }
  }
  .ant-input-number {
    width: 100%;
    height: $controlHeight;
    line-height: $controlHeight;
  }
  .ant-input-affix-wrapper {
    border-radius: $inputBorderRadius;
    .ant-input {
      height: 30px;
    }
  }

  .ant-form-item-label {
    label {
      height: $controlHeight;
    }
  }
  .ant-checkbox-wrapper {
    height: $controlHeight;
    line-height: $controlHeight;
  }
  .ant-radio-group {
    height: $controlHeight;
    // line-height: 40px;
    display: flex;
    align-items: center;
  }
  .ant-picker-input,
  .ant-input {
    height: calc($controlHeight - 8px);
  }
  .ant-picker {
    width: 100%;
    border-radius: $controlorderRadius;
  }
  .ant-select-single,
  .ant-tree-select,
  .ant-select-multiple {
    .ant-select-selector {
      height: $controlHeight !important;
      border-radius: $controlorderRadius !important;
    }
    .ant-select-selection-search-input {
      height: calc(calc($controlHeight - 8px) - 2px) !important;
    }
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      line-height: calc($controlHeight - 2px) !important;
    }
  }
  .ant-select-multiple {
    .ant-select-selection-item {
      line-height: 24px !important;
    }
  }
}

.ant-form-item-row {
  .ant-table-cell {
    padding: 12px 8px;
    .ant-form-item {
      margin-bottom: 0px !important;
    }
  }
  .form-item-action-row {
    .ant-table-cell {
      padding: 0px;
    }
    .ant-btn {
      padding: 0px;
      width: 100%;
    }
  }
}

.ant-input-textarea {
  position: relative;
  &::after {
    position: absolute;
    right: 8px;
    bottom: 8px;
    margin-bottom: 0px !important;
    z-index: 5;
  }
}

@media screen and (max-wdith: 767px) {
  .ant-col-xs-24 {
    .ant-form-item-label {
      text-align: left;
    }
  }
}

// .actionbar-fixed-form{
//   margin-bottom: 84px !important;
// }

.form-actionbar-item {
  text-align: right;
  padding-top: 20px;
  &.form-actionbar-fixed {
    position: fixed;
    margin: 0px !important;
    padding: 12px 20px;
    width: 100%;
    left: 0px;
    bottom: 0px;
    background: #fff;
    z-index: 99;
  }
}
