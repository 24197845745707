$headBgColor: #fff;
$headColor: #1c1f37;
$headHeight: 74px;

$sidebarBgColor: #527afc;
$sidebarColor: #fff;
$sidebarWidth: 260px;

@mixin calcLayout($sidebarWidth) {
  .ant-layout {
    .ant-layout-header {
      .logo-box {
        width: $sidebarWidth;
        @if $sidebarWidth < 200 {
          .title-text {
            display: none;
          }
        }
      }
      .head-content {
        width: calc(100% - $sidebarWidth);
      }
    }
  }
}

.ant-layout {
  .ant-menu {
    background: transparent;
  }
  .ant-layout-header {
    background-color: $headBgColor;
    color: $headColor;
    font-size: 20px;
    height: $headHeight;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    position: sticky;
    top: 0px;
    z-index: 2;
    .logo-box {
      // width: $sidebarWidth;
      .header {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .logo {
        width: 36px;
        height: 28px;
        display: inline-block;
        vertical-align: top;
        // background: transparent url("../../images/logo.svg") no-repeat;
      }
      text-align: center;
    }
    .head-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // width: calc(100% - $sidebarWidth);
      padding: 0 20px;
    }
  }

  .main-wrapper {
    width: 100%;
    min-height: calc(100vh - $headHeight);
    .main-content {
      width: 100%;
      height: 100%;
    }
  }

  .ant-layout-sider {
    background: $sidebarBgColor;
    width: $sidebarWidth;
    color: $sidebarColor;
    height: calc(100% - $headHeight);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .router-content {
    width: 100%;
    padding: 24px 20px;
    max-height: calc(100vh - 130px);
    overflow-y: auto;
  }
}

@include calcLayout($sidebarWidth);

@media screen and (max-width: 768px) {
  $sidebarWidth: 80px;
  @include calcLayout($sidebarWidth);
}

.ant-breadcrumb {
  background: #fff;
  padding: 20px !important;
  border-top: 1px solid #f0f2f5;
}
