.inline-filter-form {
  > .ant-row {
    margin: 0 -15px !important;
  }
  .ant-col {
    // + .ant-col {
    .ant-form-item {
      padding-left: 15px;
      padding-right: 15px;
    }
    // }
  }
  .ant-form-action-item {
    padding-bottom: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 100%;
  }
}
