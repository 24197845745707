@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./common/variables.scss";
@import "./modules/index.scss";

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
p {
  margin: 0px;
  padding: 0px;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app {
  font-family: sans-serif;
  display: flex;
  flex: 1;
  overflow: hidden;
}

.content {
  display: flex;
  flex: 1;
}

.editor-wrapper {
  flex: 1;
  // border: 1px solid black;
  padding: 40px 85px;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  min-height: 400px;
}

.editor-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  min-height: 400px;
  padding: 20px;
  .editor-item {
    background-color: #fff;
    padding: 12px 12px 12px 36px;
    position: relative;
    > * {
      width: 100%;
      pointer-events: none;
    }
    .handle {
      position: absolute;
      left: 12px;
      top: 12px;
    }
    + .editor-item {
      margin-top: 12px;
    }
    &.overlay {
      opacity: 0.5;
    }
  }
}

.spacer {
  background: black;
  width: 100%;
  opacity: 0.25;
  height: 100px;
}

$color-base: #222;

.footer {
  text-align: center;
  padding: 20px 0px 0px;
  font-size: 12px;
  color: $color-base;
  .footer-content {
    padding: px 0px 0px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      color: $color-base;
      text-decoration: none;
      display: flex;
      align-items: center;
    }
  }
  .splitline {
    width: 1px;
    height: 14px;
    background: rgba(0, 0, 0, 0.2);
    margin: 0px 8px;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 12px;
}

::-webkit-scrollbar-track {
  // background-image: linear-gradient(90deg, #2e2e2e 1px, #111 1px);
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #527afc;
  border-radius: 12px;
  /* padding-box 表示背景裁剪到内边距框，
  这里也可以用content-box,表示裁剪到内容框，
  默认为border-box，表示裁剪到边框*/
  background-clip: padding-box;
  border-style: solid;
  border-width: 3px;
  border-color: transparent;
}

/* 悬浮放大滚动滑块 */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
  /*这里默认裁剪到border-box，以下使用shadow代替了边框大小*/
  border-width: 0;
  box-shadow:
    inset 3px 3px 3px hsl(0deg 0% 100% / 25%),
    inset -3px -3px 3px rgb(0 0 0 / 25%);
}

.ant-form {
  textarea.ant-input {
    min-height: 120px;
  }
  .ant-table {
    textarea.ant-input {
      min-height: 38px;
      line-height: 30px;
    }
  }
}
