.form-section {
	border-radius: 8px;
	background-color: #fff;
	padding: 20px;

	+ .split-section {
		margin-top: 12px;
	}

	.form-section-head {
		font-size: 18px;
		font-weight: 500;
		padding-bottom: 20px;
	}
}
