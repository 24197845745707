.ck-body-wrapper {
	.ck-powered-by {
		display: none;
	}
}

#fullscreenoverlay {
	overflow: hidden;
}
#fullscreeneditor {
	position: fixed !important;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline,
	.ck.ck-editor__main {
		height: 100%;
	}
	.ck-source-editing-area{
		height: 100%;
	}
}
