.ant-table {
  border-radius: 4px;
  .ant-table-thead {
    > tr {
      > th {
        background: #fafafa;
        font-size: 14px;
        color: #666;
        height: 44px;
        line-height: 1;
      }
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      height: auto !important;
      // padding: 0px 12px;
      line-height: 1;
    }
  }
}
