* {
  box-sizing: border-box;
}

body {
  margin: 0px;
  padding: 0px;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

ul,
ul > li {
  list-style: none;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background: #5a7ff9;
}
::-webkit-scrollbar-button {
  background-color: #aaa;
  display: none;
}
::-webkit-scrollbar-corner {
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background-color: #fff;
}

.success {
  color: #b7eb8f;
}

.error {
  color: #ff4d4f;
}
