.table-wrapper {
  .action-bar {
    margin-bottom: 15px;
  }
}
.table-column-check-list {
  .ant-checkbox-group-item {
    display: block;
  }
}

// table
.ant-table-thead > tr > th {
  background: #e8f4ff;
  padding: 11px;
  font-size: 12px;
  color: #5b5b5b;
  //   font-weight: 100 ;
  &:first-child {
    padding-left: 20px;
  }
  &.ant-table-column-sort {
    background-color: #e8f4ff;
    &:hover {
      background-color: #e8f4ff;
    }
  }
}
.ant-table-column-sorters {
  padding: 0;
}
.ant-table-tbody > tr > td {
  color: #7d7e8e;
  // padding: 24px 16px;
  &.ant-table-column-sort {
    background-color: transparent;
  }
}

.ant-table-tbody > tr:nth-child(even) > td {
  background-color: #f8f8f8;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #e8f4ff;
}

// 可展开的table，隔行变色
.expanded-table {
  .ant-table-tbody > tr.ant-table-row td {
    background-color: #fff;
  }
  .ant-table-tbody > tr.ant-table-row + tr.ant-table-expanded-row td {
    background-color: #fff;
  }

  .ant-table-tbody > tr.greyColor td {
    background-color: #f8f8f8;
  }
  .ant-table-tbody > tr.greyColor + tr.ant-table-expanded-row td {
    background-color: #f8f8f8;
  }
}

.filter-form {
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  background: #fff;
}
