$modalRadius: 8px;

.ant-modal {
    .ant-modal-content {
        border-radius: $modalRadius;
    }

    .ant-modal-header {
        border-radius: $modalRadius;

    }
    .ant-modal-footer{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-flow: row nowrap;
    }
}