.upload-card{
    width: 120px;
    min-height: 120px;
    height: auto;
}

.ant-upload-select-picture-card{
    .add-outline-box{
        position: absolute;
        top: 18px;
        height: 16px;
    }
    .ant-upload-text{
        padding-top: 22px;
    }
    >.ant-upload{
        flex-flow: column nowrap;
    }
}