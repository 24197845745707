$bodyColor: #5a7ff9;

$tabBgColor: #fff;
$tabHeadColor: #333333;
$tabHeadActiveColor: #4477f6;

$btnDefaultBgColor: #eeeeee;
$btnDefaultColor: #333;

$btnPrimaryBgColor: #4477f6;
$btnPrimaryColor: #fff;

$imgPath: "../images/";

$baseBorderRadius: 6px;
$baseControlHeight: 40px;
