.table-layout {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  .table-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  .table-action-bar {
    display: flex;
    gap: 12px;
  }
}

@media screen and (max-width: 768px) {
  .table-layout {
    .table-head {
      flex-flow: column nowrap;
      justify-content: center;
    }

    .table-action-bar {
      flex: 1 1 100%;
      flex-flow: column nowrap;
      width: 100%;
      .ant-space {
        flex: 1 1 100%;
        display: flex;
        flex-flow: column nowrap;
        .ant-space-item{
          width: 100%;
          > *,.ant-btn,.ant-upload{
            display: block;
            width: 100%;
          }
        }
      }
    }
    .ant-pagination-total-text{
      display: none;
    }
  }
}
