$bodyColor: #5a7ff9;

body {
  background-color: $bodyColor;
  font-size: 14px;
}

.login-container {
  background-color: $bodyColor;
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-wrapper {
    .login-head {
      font-size: 24px;
      text-align: center;
      padding: 40px 0;
      img{
        margin-right: 10px;
      }
    }
    .login-footer {
      padding-top: 40px;
      text-align: center;
    }
    width: 1208px;
    position: relative;
    .login-main {
      .wx-login {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        iframe {
          width: 220px;
        }
      }
      .account-login {
        padding: 0px 80px;
        width: 100%;
        .field-item {
          padding: 10px 0;
        }
        .ant-btn-primary {
          width: 100%;
          height: 50px;
          background: #4477f6;
          border-radius: 4px;
          font-size: 20px;
        }
        .auth-input {
          .ant-input {
            border-radius: 4px 0 0 4px;
          }
          .ant-input-group-addon {
            padding: 0;
          }
          .ant-btn {
            border-radius: 0 4px 4px 0;
            border: 0;
            height: 100%;
            width: 100%;
            background-color: transparent;
            box-shadow: none;
            font-size: 16px;
            padding: 8px;
          }
        }
      }
      background: #edf1fe;
      border-radius: 8px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;
      height: 630px;
      width: 100%;
      .login-content {
        width: 590px;
        background-color: #fff;
        height: 100%;
        .ant-tabs-nav {
          &::before {
            border-color: #e8e8e8;
          }
          &.ant-tabs-nav-active {
            .ant-tabs-tab-btn {
              color: #4477f6;
            }
          }
          height: 113px;
          margin: 0;
          padding-top: 40px;
          .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
              width: 100%;
              .ant-tabs-tab {
                font-size: 20px;
                width: 50%;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
        .ant-tabs-content {
          height: 516px;
          .ant-tabs-tabpane {
            height: 100%;
          }
        }
      }
    }
  }
  .ant-form-item-control-input {
    .ant-form-item-control-input-content {
      .ant-input {
        border-radius: 4px;
        height: 40px;
        font-size: 16px;
      }
      .ant-input-prefix{
        color: #999;
        font-size: 16px;
        margin-right: 10px;
      }
    }
  }
}
